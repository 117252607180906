import { library, dom, config } from '@fortawesome/fontawesome-svg-core'
import {
	faSearch as faSearchLight,
	faSync as faSyncLight,
	faGlobe as faGlobeLight,
	faFileWord as faFileWordLight,
	faFilePowerpoint as faFilePowerpointLight,
	faFileImage as faFileImageLight,
	faFileSpreadsheet as faFileSpreadsheetLight,
	faFileArchive as faFileArchiveLight,
	faFilePdf as faFilePdfLight,
	faFile as faFileLight,
	faPlayCircle as faPlayCircleLight,
	faEdit as faEditLight,
	faSpinner as faSpinnerLight,
	faUser as faUserLight,
	faSquare as faSquareLight,
	faLink as faLinkLight,
	faQuoteLeft as faQuoteLeftLight,
	faQuoteRight as faQuoteRightLight,
	faCircle as faCircleLight,
	faAngleRight as faAngleRightLight,
	faAngleLeft as faAngleLeftLight,
	faPlusCircle as faPlusCircleLight,
	faMinusCircle as faMinusCircleLight,
} from '@fortawesome/pro-light-svg-icons'
import {
	faSync as faSyncRegular,
	faChevronDown as faChevronDownRegular,
	faChevronLeft as faChevronLeftRegular,
	faChevronRight as faChevronRightRegular,
} from '@fortawesome/pro-regular-svg-icons'
import {
	faDownload as faDownloadSolid,
	faLink as faLinkSolid,
	faSpinner as faSpinnerSolid,
} from '@fortawesome/pro-solid-svg-icons'

config.searchPseudoElements = true
config.keepOriginalSource = false

library.add(
	// light
	faSearchLight,
	faSyncLight,
	faGlobeLight,
	faFileWordLight,
	faFilePowerpointLight,
	faFileImageLight,
	faFileSpreadsheetLight,
	faFileArchiveLight,
	faFilePdfLight,
	faFileLight,
	faPlayCircleLight,
	faEditLight,
	faSpinnerLight,
	faUserLight,
	faSquareLight,
	faLinkLight,
	faQuoteRightLight,
	faQuoteLeftLight,
	faAngleRightLight,
	faAngleLeftLight,
	faPlusCircleLight,
	faMinusCircleLight,

	// solid
	faDownloadSolid,
	faLinkSolid,
	faSpinnerSolid
)

dom.i2svg()
dom.watch()
